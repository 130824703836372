import { InteractionConfig } from './types';
import { AnimatePresence, motion } from 'framer-motion';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	// call after preloading is done
	props.onLoaded();
	// call onDone when your interaction is done or after some timeout
	setTimeout(() => {
		props.onDone();
	}, props.config.timeout);

	return (
		<AnimatePresence>
			<div className="relative w-full h-full">
				<audio src="/notif.mp3" autoPlay={true} />
				<motion.div

					initial={{ opacity: 0, y: 50 }}
					exit={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2, delay: 0 }}
				>
					<div className="bg-[#6264a7] border-gray-400 p-4 w-full shadow-md ">

						<div className='flex items-center space-x-4'> {/* Updated to items-center */}
							{/* <img alt="jesus" src='./logo.png' className='h-12 shadow-md'></img> */}
							<div className='flex items-center justify-center rounded-full bg-orange-400 text-white w-16 h-16 text-4xl text-center font-bold'>T</div>
							<div className='flex-1 min-w-[400px] max-w-lg break-words'>

								<h2 className="text-md font-a font-bold text-white">{props.config.msgFrom}</h2>
								{/* <h2 className="text-md font-a font-bold text-white">#Chat</h2> */}
								<p className="text-left text-md font-a text-white overflow-hidden">{props.config.msg}</p>
							</div>
							<div className='flex justify-end mt-[-10%]'>
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
									<path strokeLinecap="round" strokeLinejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
								</svg>
							</div>
						</div>
					</div>
					<div className="bg-[#565a97] border-gray-400 p-4 w-full shadow-md">
						<div className='flex items-left space-x-4'>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="white" className="w-6 h-6">
								<path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 0 0 -12h3" />
							</svg>
							<div className='text-white text-md'>
								Reply
							</div>
						</div>

					</div>
				</motion.div>
			</div>
		</AnimatePresence>
	);
}
